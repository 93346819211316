/* ------------------------------------------------------------------
  * Libs
------------------------------------------------------------------ */
import 'jquery-validation'
// import './libs/matchHeight'
// import './libs/slider'
// import './libs/infiniteScroll'
import './libs/validation/index'

/* ------------------------------------------------------------------
  * Plugins
------------------------------------------------------------------ */
// import './plugins/closestOpposite'
// import './plugins/getDevice'
// import './plugins/getParam'
// import './plugins/handleHtmlClass'

/* ------------------------------------------------------------------
  * Scripts
------------------------------------------------------------------ */
import './customs/gas/form'
// import './customs/ccFormChange'
// import './customs/clearCheckBox'
// import './customs/clearSelect'
// import './customs/ripple'
// import './customs/tab'
// import './customs/tab/onScrollingTab'
// import './customs/goToTop'
// import './customs/smoothScroll'
// import './customs/fadeInScroll'
// import './customs/modal'
// import './customs/navMenu'
// import './customs/headerTooltip'
// import './customs/handleFavorite'
// import './customs/handleScalable'
// import './customs/alertClose'
// import './customs/changeSelectColor'
// import './customs/userAgent'
