$(function(){
  $('.js-form').validate({
    debug: false,
    rules: {
      name :{
        required: true
      }
    },
    messages: {
      ご氏名 :{
        required: "お名前が未入力です。"
      },
      メールアドレス :{
        required: "有効なメールアドレスを入力してください。"
      },
      お問い合わせ内容 :{
        required: "ご用件が未入力です。"
      },
    },
    submitHandler: function(e) {
      var btn_submit, form;
      form = $(e);
      btn_submit = form.find('input[type=submit]');

      if (window.confirm('送信してよろしいですか？')) {
        return $.ajax({
          url: form.attr('action'),
          dataType: 'jsonp',
          data: form.serialize(),
          beforeSend: function() {
            return btn_submit.attr('disabled', true);
          },
          complete: function() {
            var url = location.href;
            var nextPage = url + 'completed.html';
            document.contactform.reset();
            // window.confirm('お問い合わせありがとうございます。メールの送信が完了しました');
            window.location.href = nextPage;
            return btn_submit.attr('disabled', false);
          },
          success: function(response) {
            // document.contactform.reset();
            // return window.confirm('お問い合わせありがとうございます。メールの送信が完了しました');
            // return console.log(response);
          },
          error: function(response) {
            // return window.confirm('メールの送信ができませんでした。お手数ですが、しばらく時間を置いてからお試しください。');
            // return console.log(response);
          }
        });
      } else {
        return false;
      }
    }
  });
});
