jQuery.extend(jQuery.validator.messages, {
  required: "必須項目です",
  remote: "このフィールドを修正してください。",
  email: "有効なEメールアドレスを入力してください。",
  url: "有効なURLを入力してください。",
  date: "有効な日付を入力してください。",
  dateISO: "有効な日付を入力してください。",
  number: "有効な数字を入力してください。",
  digits: "数字のみを入力してください。",
  creditcard: "有効なクレジットカード番号を入力してください。",
  equalTo: "同じ値でもう一度入力してください。",
  extension: "有効な拡張子を含む値を入力してください。",
  maxlength: $.validator.format("{0} 文字以内で入力してください。"),
  minlength: $.validator.format("{0} 文字以上で入力してください。"),
  rangelength: $.validator.format("{0} 文字から {1} 文字までの値を入力してください。"),
  range: $.validator.format("{0} から {1} までの値を入力してください。"),
  step: $.validator.format("{0} の倍数を入力してください。"),
  max: $.validator.format("{0} 以下の値を入力してください。"),
  min: $.validator.format("{0} 以上の値を入力してください。")
});
jQuery.validator.addMethod("alphanum", function (value, element) {
  return this.optional(element) || /^([a-zA-Z0-9|\s]+)$/.test(value);
}, "半角英数字を入力してください");
jQuery.validator.addMethod("tel", function (value, element) {
  return this.optional(element) || /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/.test(value.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi, ''));
}, "電話番号を正確にご入力ください");
var validator = $('.js-validation-form').validate({
  debug: true,
  rules: {
    company_name: {
      required: true,
      maxlength: 100
    },
    zipcode: {
      required: true
    },
    address: {
      required: true
    },
    tel: {
      required: true,
      tel: true
    },
    homepage: {
      required: true
    },
    department: {
      required: true
    },
    department_tel: {
      required: true,
      tel: true
    },
    user_id: {
      required: true,
      alphanum: true
    },
    user_password: {
      required: true,
      alphanum: true,
      minlength: 8
    },
    user_password_confirm: {
      required: true,
      alphanum: true,
      minlength: 8,
      equalTo: '.js-validation-user-password'
    }
  },
  messages: {
    user_id: {
      required: 'ログインIDが未入力です。'
    },
    user_email: {
      required: 'メールアドレスが未入力です。'
    },
    user_password: {
      required: 'パスワードが未入力です。',
      minlength: 'パスワードは8桁以上でご入力ください。'
    },
    user_password_confirm: {
      required: 'パスワード再入力が未入力です。',
      minlength: 'パスワードは8桁以上でご入力ください。',
      equalTo: 'パスワードで入力されたものと異なります。'
    }
  },
  errorPlacement: function errorPlacement(err, element) {
    element.before(err);
  }
});
$(function () {
  if (!$('.js-validation-form')) {
    return;
  };

  // $('.js-validation-company-name').blur(function () {
  //   validator.element('.js-validation-company-name');
  // });
  // $('.js-validation-zipcode').blur(function () {
  //   validator.element('.js-validation-zipcode');
  // });
  // $('.js-validation-address').blur(function () {
  //   validator.element('.js-validation-address');
  // });
  // $('.js-validation-tel').blur(function () {
  //   validator.element('.js-validation-tel');
  // });
  // $('.js-validation-homepage').blur(function () {
  //   validator.element('.js-validation-homepage');
  // });
  // $('.js-validation-department').blur(function () {
  //   validator.element('.js-validation-department');
  // });
  // $('.js-validation-department-tel').blur(function () {
  //   validator.element('.js-validation-department-tel');
  // });
  $('.js-validation-user-email').blur(function () {
    validator.element('.js-validation-user-email');
  });
  $('.js-validation-user-password').blur(function () {
    validator.element('.js-validation-user-password');
  });
  $('.js-validation-user-password-reference').blur(function () {
    validator.element('.js-validation-user-password-reference');
  });
  // $('.js-validation-form input').on('keyup blur', function () {
  //   // jquery validate pluginのエラーがない時かつinputに空欄がない時にボタンをアクティブに変更
  //   if (validator.numberOfInvalids() === 0 && $(".js-validation-form input:blank").length === 0) {
  //     $('.js-validation-submit').prop('disabled', false);
  //     $('.js-validation-submit').closest('.c-square-button').removeClass('-disabled');
  //   } else {
  //     $('.js-validation-submit').prop('disabled', 'disabled');
  //     $('.js-validation-submit').closest('.c-square-button').addClass('-disabled');
  //   }
  // });
});
